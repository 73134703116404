import { sortBy, sumBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import spacetime from 'spacetime';
import { OPEN_COLLECTIVE_FALLBACK, SPONSORS_INPUT } from '../constants';
import {
  Contribution,
  useOpenCollectiveContext,
} from '../context/OpenCollectiveContext';
import { randomNumber } from '../utils';
import { useLocalStorage } from './useLocalStorage';

export const useDonationQueue = () => {
  const { statistic, contributions } = useOpenCollectiveContext();
  const [lastShownId, setLastShownId] = useLocalStorage<string | null>(
    'last_shown_id',
    null
  );
  const [testContributions, setTestContributions] = useState<Contribution[]>(
    []
  );
  const [donationQueue, setDonationQueue] = useState<Contribution[]>([]);

  // useEffect(() => {
  //   console.log('RESET LOCAL STORAGE');
  //   setLastShownId(null);
  // }, []);

  const onShowNotification = useCallback(
    (contribution: Contribution) => {
      if (contribution?.createdAt) {
        setLastShownId(contribution.createdAt);
      }
    },
    [setLastShownId]
  );

  const openCollective = useMemo(() => {
    return (
      (statistic?.totalNetAmountReceived || OPEN_COLLECTIVE_FALLBACK) -
      sumBy(donationQueue, 'amount.value')
    );
  }, [donationQueue, statistic?.totalNetAmountReceived]);

  const currentSumToShow = useMemo(() => {
    return SPONSORS_INPUT + openCollective;
  }, [openCollective]);

  useEffect(() => {
    if (contributions?.length) {
      console.log('lastShownId', lastShownId);
      const nextDonationQueue = [...contributions, ...testContributions].filter(
        (contribution) =>
          lastShownId
            ? spacetime(contribution.createdAt).isAfter(spacetime(lastShownId))
            : true
      );
      console.log(
        '====',
        contributions,
        '-->',
        lastShownId ? spacetime(lastShownId) : null,
        '-->',
        nextDonationQueue,
        '---sorted---',
        sortBy(nextDonationQueue, 'createdAt')
      );
      setDonationQueue(sortBy(nextDonationQueue, 'createdAt'));
    }
  }, [contributions, testContributions, lastShownId]);

  // TEST DATA
  const onAddTestDonation = useCallback(() => {
    setTestContributions((queue) => [
      {
        amount: {
          value: randomNumber(10, 1000),
        },
        createdAt: spacetime().format('iso-utc'),
        fromAccount: {
          name: 'Test',
        },
      },
      ...queue,
    ]);
  }, []);
  // END OF TEST DATA

  return {
    donationQueue,
    currentSumToShow,
    onShowNotification,
    onAddTestDonation,
  };
};
