import party from 'party-js';
import { EmojiImages } from '../constants';
import { Emoji } from '../types';

party.resolvableShapes[
  Emoji.HeartBlue
] = `<img src=${EmojiImages.HeartBlue} width=24/>`;
party.resolvableShapes[
  Emoji.HeartYellow
] = `<img src=${EmojiImages.HeartYellow} width=24/>`;
party.resolvableShapes[Emoji.Hand] = `<img src=${EmojiImages.Hand} width=24/>`;
party.resolvableShapes[Emoji.Flag] = `<img src=${EmojiImages.Flag} width=24/>`;

party.settings.gravity = 500;

/**
 * The configuration to apply to the confetti.
 */
export interface ConfettiConfiguration {
  count: party.variation.Variation<number>;
  spread: party.variation.Variation<number>;
  speed: party.variation.Variation<number>;
  size: party.variation.Variation<number>;
  rotation: party.variation.Variation<party.Vector>;
  color: party.variation.Variation<party.Color>;
  shapes: party.variation.Variation<string | HTMLElement>;
  modules: party.ModuleFunction[];
}

/**
 * The standard confetti template.
 *
 * @param source The source to emit the confetti from.
 * @param options The (optional) configuration overrides.
 */
export function customConfetti(
  source: party.sources.DynamicSourceType,
  options?: Partial<ConfettiConfiguration>
): party.Emitter {
  const populated = party.util.overrideDefaults(
    {
      count: party.variation.range(20, 40),
      spread: party.variation.range(35, 45),
      speed: party.variation.range(300, 600),
      size: party.variation.skew(1, 0.2),
      rotation: () => party.random.randomUnitVector().scale(180),
      color: () =>
        party.Color.fromHsl(party.random.randomRange(0, 360), 100, 70),
      modules: [
        new party.ModuleBuilder()
          .drive('size')
          .by((t) => Math.min(1, t * 3))
          .relative()
          .build(),
      ],
      shapes: ['square', 'circle'],
    },
    options
  );

  const emitter = party.scene.current.createEmitter({
    emitterOptions: {
      loops: 1,
      duration: 4,
      modules: populated.modules,
    },
    emissionOptions: {
      rate: 0,
      bursts: [{ time: 0, count: populated.count }],

      sourceSampler: party.sources.dynamicSource(source),
      angle: party.variation.skew(
        -90,
        party.variation.evaluateVariation(populated.spread)
      ),

      initialLifetime: 8,
      initialSpeed: populated.speed,
      initialSize: populated.size,
      initialRotation: populated.rotation,
      initialColor: populated.color,
    },
    rendererOptions: {
      applyLighting: undefined,
      shapeFactory: populated.shapes,
    },
  });

  return emitter;
}
