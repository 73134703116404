import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { animated, useSpring, useTransition } from 'react-spring';
import { Contribution } from '../context/OpenCollectiveContext';
import { formatPrice } from '../utils/priceFormatter';
import QRCode from '../assets/images/donate-qr-code.svg';
import { getRandomEmoji } from '../utils';
import { useDonationQueue } from '../hooks/useDonationQueue';
import spacetime from 'spacetime';
import party from 'party-js';
import { EmojiImages, MILESTONES } from '../constants';
import { Emoji } from '../types';
import { customConfetti } from '../utils/partyAnimation';
// import { useTestOverlay } from '../hooks/useTestOverlay';

const ANIMATION_DURATION = 4000;
const ANIMATION_BREATH = 1000 * 30;

const VideoOverlay = () => {
  const {
    donationQueue,
    currentSumToShow,
    onShowNotification,
    // onAddTestDonation,
  } = useDonationQueue();

  // const { testRender } = useTestOverlay(onAddTestDonation);

  const refMap = useMemo(() => new WeakMap(), []);
  const [items, setItems] = useState<(Contribution & { emoji: Emoji })[]>([]);
  const [isReady, setReady] = useState<boolean>(true);
  const timeoutRefs = useRef<Map<string, NodeJS.Timer>>(new Map());
  const generalTimeoutRef = useRef<NodeJS.Timer>();
  const [target, setTarget] = useState<number>();

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--body-background-color',
      'transparent'
    );
  }, []);

  useEffect(() => {
    setTarget(
      MILESTONES.filter((milestone) => milestone > currentSumToShow)[0]
    );
  }, [currentSumToShow]);

  const currentValue = useSpring({
    value: currentSumToShow,
    from: { value: 0 },
  });
  const targetValue = useSpring({
    value: target,
    from: { value: currentSumToShow },
  });

  const transitions = useTransition(items, {
    keys: (item) => item.createdAt,
    from: {
      opacity: 0,
      width: 0,
      x: -20,
      life: '100%',
    },
    enter: (item) => async (next) => {
      onShowNotification(item);
      await next({
        opacity: 1,
        x: 0,
        width: refMap.get(item)?.offsetWidth,
      });
      if (item.amount.value >= 100) {
        customConfetti(refMap.get(item), {
          shapes: [item.emoji],
          count: party.variation.range(8, 12),
          spread: party.variation.range(16, 90),
          speed: party.variation.range(200, 360),
          size: party.variation.skew(1, 0.2),
          rotation: () => new party.Vector(0, 0, 0),
        });
      }
      await next({ life: '0%' });
    },
    leave: (item) => [
      {
        opacity: 0,
        x: refMap.get(item)?.offsetWidth,
        width: 0,
      },
    ],
    onRest: (_result, _ctrl, item) => {
      setItems((state) => state.filter((i) => i.createdAt !== item.createdAt));
    },
    config: (_item, _index, phase) => (key) =>
      phase === 'enter' && key === 'life'
        ? { duration: ANIMATION_DURATION }
        : { tension: 125, friction: 20, precision: 0.1 },
  });

  const updateItems = useCallback((donations: Contribution[]) => {
    const first = donations[0];
    if (first) {
      const itemsInThisMinute = donations.filter((contribution) =>
        spacetime(contribution.createdAt).isBefore(
          spacetime(first.createdAt).add(1, 'minute')
        )
      );
      console.log('itemsInThisMinute', itemsInThisMinute);

      itemsInThisMinute.forEach((contribution, index) => {
        if (index === 0) {
          if (generalTimeoutRef.current) {
            clearTimeout(generalTimeoutRef.current);
          }
          setReady(false);
          generalTimeoutRef.current = setTimeout(() => {
            setReady(true);
          }, ANIMATION_BREATH);
        }

        const timeout = setTimeout(() => {
          setItems((items) => [
            { ...contribution, emoji: getRandomEmoji() },
            ...items,
          ]);
          timeoutRefs.current.delete(contribution.createdAt);
        }, (index + 1) * 2000);
        console.log('**itemsInThisMinute', contribution);
        timeoutRefs.current.set(contribution.createdAt, timeout);
      });
    }
  }, []);

  useEffect(() => {
    console.log('useEffect', timeoutRefs.current.size, donationQueue.length);
    if (!timeoutRefs.current.size && donationQueue.length && isReady) {
      console.log('--settings', donationQueue);
      updateItems(donationQueue);
    }
  }, [donationQueue, isReady, updateItems]);

  return (
    <div className="relative h-screen w-screen overflow-hidden">
      {/* {testRender} */}

      <div className="absolute py-9 bottom-0 left-0 right-0 flex gap-6">
        <div className="relative flex flex-col justify-end gap-6 flex-1">
          <div className="flex pl-9 gap-4 flex-wrap relative h-14 overflow-y-hidden">
            {transitions(({ life, ...style }, item) =>
              item?.fromAccount?.name ? (
                <animated.div style={style}>
                  <div
                    className="w-fit px-4 text-xl rounded-full h-14 text-primary border border-primary bg-translucent-background-50 whitespace-nowrap flex items-center"
                    ref={(ref: HTMLDivElement) =>
                      ref && !refMap.get(item) && refMap.set(item, ref)
                    }
                  >
                    {`${item.fromAccount.name} donated ${formatPrice(
                      item.amount.value
                    )}`}
                    <div className="ml-2 w-6 h-6">
                      <img src={EmojiImages[item.emoji]} className="h-full" />
                    </div>
                  </div>
                </animated.div>
              ) : null
            )}
          </div>

          <div className="flex gap-4 pl-9">
            <div className="relative h-14 tabular-nums flex-1">
              <animated.progress
                className="progress progress-primary h-14 bg-neutral-800 rounded-full"
                value={currentValue.value}
                max={target}
              />
              <div className="absolute text-2xl top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex justify-center text-neutral-900">
                <animated.div className="number">
                  {currentValue.value.interpolate((val) => formatPrice(val))}
                </animated.div>
                <span className="mx-1 opacity-70">/</span>
                <animated.div className="number opacity-70">
                  {targetValue.value.interpolate((val) => formatPrice(val))}
                </animated.div>
              </div>
            </div>
          </div>
        </div>

        <div className="mr-9 p-2 bg-primary-content">
          <img src={QRCode} alt="Donate" className="w-[144px] h-[144px]" />
        </div>
      </div>
    </div>
  );
};

export default VideoOverlay;
